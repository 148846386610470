import Router from 'next/router'
import { staticFile } from '@common/utils'

export default function NotFound() {
  return (
    <div className="flex flex-col justify-center items-center h-screen w-screen">
      <img
        src={staticFile('/images/error-page/404.svg')}
        alt="Codingal error 404"
        className="h-auto w-3/4 md:w-4/12"
      />
      <h1 className="font-600 text-2xl mt-8">Oops! Page not found</h1>
      <button
        className="bg-orange font-600 text-white px-10 py-2 my-6 rounded hover:bg-orange-500 focus:outline-none"
        onClick={() => Router.push('/')}
      >
        Go to Home
      </button>
    </div>
  )
}
